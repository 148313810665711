<template>
  <div class="listOne">
    <div class="image">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="layout">
      <div class="contentlistVideo">
        <div class="left">
          <div class="listSlid">
            <div class="listSlidHd" @click="menuShow">
              <span>{{ slideHdTitle }}</span>
              <i class="iconfont" v-if="!isMenuShow" style="color: #fff;">&#xe65f; </i>
              <i class="iconfont" v-if="isMenuShow" style="color: #fff;">&#xe65e; </i>
            </div>
            <div class="listSlidList" v-if="isMenuShow">
              <div
                class="listSlidListInner"
                :class="{ on: active == item.entTChannelUuid }"
                v-for="(item, index) in slideList"
                :key="index"
              >
                <a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a
                  v-if="
                    (!item.url || item.url == '') &&
                      item.entTChannelUuid != '01830005' &&
                      item.entTChannelUuid != '01830001' &&
                      item.entTChannelUuid != '01860001' &&
                      item.entTChannelUuid != '01860004'
                  "
                  :href="
                    item.catalog === 1
                      ? `/listPage/${item.entTChannelUuid}`
                      : item.catalog === 2
                      ? `/list/${item.entTChannelUuid}`
                      : `/listPic/${item.entTChannelUuid}`
                  "
                >
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01830005'" :href="`/listPicTwo/01830005`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01830001'" :href="`/listVideo/01830001`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01860001'" :href="`/listVideo/01860001`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01860004'" :href="`/listPicTwo/01860004`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="listMainRight fr">
          <div class="listWrap">
            <div class="listHd clearfloat">
              <div class="fl listHdGrop">
                {{ $t('m.listPageOption') }}：<a href="/" style="font-weight: 700; color: #303133;">{{
                  $t('m.listPageHome')
                }}</a>
                <span v-if="viewSlideHdParent">
                  > <a :href="slideHdParentUrl">{{ slideHdParentName }}</a> ></span
                >
                <span style="color: #0162b3;">&nbsp;{{ slideHdName }}</span>
              </div>
            </div>
            <div class="listContainer">
              <div class="listVideo">
                <div class="listVideoBox">
                  <video ref="middleVideo" class="video-js"></video>
                </div>
                <div class="listVideoTit">
                  {{ pageTitle }}
                </div>
              </div>
              <div class="listpagination" v-if="listData.length != 0">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  :pager-count="11"
                  @current-change="currentChange"
                >
                </el-pagination>
              </div>
              <div v-if="listData.length == 0 || this.noempty">
                <el-empty></el-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getChildChannel,
  getChannelInfoNew,
  articles,
  // getCookie,
  getArtInfo
  // getAllArtListByParentUuid
} from '@/api/common'

export default {
  name: 'list',
  data() {
    return {
      isMenuShow: true,
      isthird: false,
      isClickid: '',
      isClickid2: '',
      slideHdName1: '',
      slideHdName2: '',
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻动态'
      },
      slideList: [],
      pageSize: 1,
      pageNum: 1,
      total: 0,
      listData: [],
      listDataEn: [],
      listId: '',
      indexImg: '',
      noempty: false,
      videoOptions: {
        // playbackRates: [1.0, 1.5, 2.0], // 播放速度
        autoplay: true, // 如果true，浏览器准备好时开始回放
        controls: true,
        muted: true, // 默认情况下将会消除任何音频
        loop: true, //循环播放
        preload: 'auto', // <video>加载元素后立即加载视频
        language: 'zh-CN',
        aspectRatio: '16:9', //流畅模式，并计算播放器动态大小时使用该值
        // fluid: tr//设置小喇叭音量键
        controlBar: {
          fullscreenToggle: true,
          // 音量条的横向还是纵向显示
          volumePanel: {
            inline: false
          }
        },
        sources: [
          {
            src: '',
            type: 'video/mp4'
          }
        ],
        // poster: 'http://vjs.zencdn.net/v/oceans.png', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试'
      },
      pageTitle: '',
      player: null,
      applicationUuid: '',
      lang: 'zh-CN'
    }
  },
  filters: {
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 120) {
        return value.slice(0, 120) + '...'
      }
      return value
    },
    ellipsis2: function(value) {
      if (!value) return ''
      if (value.length > 45) {
        return value.slice(0, 45) + '...'
      }
      return value
    }
  },
  mounted() {
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id

    // let lang = getCookie('lang')
    let lang = localStorage.getItem('lang')
    if (lang == 'en-US') {
      this.applicationUuid = this.webIdEn
    } else {
      this.applicationUuid = this.webId
    }
    // _this.getChildChannel()
    _this.getChannelInfoNew()
    _this.articles()
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    menuShow() {
      this.isMenuShow = !this.isMenuShow
    },
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this
      let data = {
        applicationUuid: this.applicationUuid,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    getChannelInfoNew() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.id,
        applicationUuid: this.applicationUuid
      }
      getChannelInfoNew(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
          if (data.parentUuid == '0') {
            _this.slideHdTitle = data.name
            _this.viewSlideHdParent = false
            if (data.fileName && data.fileName !== '') {
              _this.indexImg = data.fileName
            } else {
              _this.indexImg = require('../assets/image/partyBuilding.jpg')
            }
            return
          }

          _this.getChildChannel(data.parentUuid)

          let params = {
            channelUuid: data.parentUuid,
            applicationUuid: this.applicationUuid
          }
          getChannelInfoNew(params).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.slideHdParentName = data.name
              _this.slideHdTitle = data.name
              if (data.fileName && data.fileName !== '') {
                _this.indexImg = data.fileName
              } else {
                _this.indexImg = require('../assets/image/partyBuilding.jpg')
              }
            }
          })
        }
      })
    },
    articles() {
      var _this = this

      let data = {
        channelUuid: _this.$route.params.id,
        entTApplicationUuid: this.applicationUuid,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.listData = data.rows
          data.rows.length && _this.getArtInfo(data.rows[0].entTArticleUuid)
          // console.log(9999,data.rows[0].entTArticleUuid)
          _this.total = data.totalRows
        } else {
          this.noempty = true
        }
      })
    },
    // 查询文章详情
    getArtInfo(params) {
      var _this = this
      let data = {
        entTApplicationUuid: this.applicationUuid,
        entTArticleUuid: params
      }
      getArtInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.pageTitle = data.title
          const videoUrlMatch = data.content.match(/<source\s+src=["']([^"']+)["']/)
          const videoUrl = videoUrlMatch[1]

          if (_this.player) {
            _this.player.src({ src: videoUrl })
          } else {
            _this.videoOptions.sources[0].src = videoUrl
            setTimeout(() => {
              _this.initVideo()
            }, 500)
          }
        }
      })
    },
    currentChange(e) {
      // console.log('e :>> ', e);
      var _this = this
      _this.pageNum = e
      _this.articles()
    },
    // 初始化视频方法
    initVideo() {
      this.player = this.$video(this.$refs.middleVideo, this.videoOptions)
    }
  }
}
</script>
<style>
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff2?t=1661313604254') format('woff2'),
    url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff?t=1661313604254') format('woff'),
    url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.ttf?t=1661313604254') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.listOne .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.contentlistVideo {
  min-width: 1200px;
  min-height: 300px;
  display: flex;
  margin: 20px 0 30px;
}
.left {
  width: 240px;
}
.listMainRight {
  width: 940px;
  padding: 0 0 20px 20px;
  overflow: hidden;
}
.listHd {
  height: 50px;
  display: flex;
}
.listHdGrop {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  opacity: 0.6;
}
.listOne .list:hover {
  box-shadow: 0 0 24px 0 hsla(0, 0%, 42.7%, 0.4);
  transition-duration: 0.5s;
  cursor: pointer;
}

.listOne .list {
  width: 920px;
  height: 110px;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.listOne .listContainer .listImgOne {
  width: 238px;
  height: 110px;
  padding-left: 15px;
  float: left;
}
.listOne .listContent {
  width: 520px;
  overflow: hidden;
  height: 110px;
  float: left;
  padding: 0 13px;
}
.listOne .listContent span,
.listContent .title {
  font-family: Microsoft YaHei;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.listOne .listContent span {
  font-size: 14px;
  font-weight: 400;
  color: #444;
  line-height: 30px;
  max-height: 60px;
  -webkit-line-clamp: 2;
}
.listOne .listContent span,
.listContent .title {
  font-family: Microsoft YaHei;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.listOne .listContent .title {
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  line-height: 40px;
  -webkit-line-clamp: 1;
}
.listOne .time {
  width: 120px;
  padding: 10px 0;
  float: right;
}
.listOne .time p {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bbb;
  line-height: 30px;
  margin-bottom: 40px;
}
.listOne .time span {
  display: block;
  width: 50px;
  height: 25px;
  border-left: 2px solid #ddd;
  margin-top: 10px;
  text-align: center;
}
.listOne .time span img {
  width: 25px;
  height: 20px;
  padding-top: 3px;
}
.listOne .listContainer {
  width: 920px;
  height: auto;
}
.listOne .listpagination {
  margin-top: 20px;
}
.listVideo {
  text-align: center;
  /* border: 1px solid #bbb; */
}
.listVideoBox {
  height: 530px;
  text-align: center;
}
.listVideoTit {
  height: 50px;
  font-size: 18px;
  color: #333333;
  line-height: 50px;
}
.video-js {
  width: 100%;
  height: 100% !important;
}
</style>
